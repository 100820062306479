import { clientFetch } from '@/common/api/rest/fetch.client'
import { ProductListItem } from '@/common/types'

export async function fetchTopProducts(
  lastViewedSKUs: string[],
): Promise<ProductListItem[]> {
  const searchParams = new URLSearchParams()

  if (lastViewedSKUs.length > 0) {
    searchParams.append('skus', lastViewedSKUs.join(','))
  }

  const result = await clientFetch<ProductListItem[]>({
    url: `/api/v1/top-products?${searchParams.toString()}`,
  })

  if (!result) {
    return []
  }

  return result
}
