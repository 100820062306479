import { ProductFlashMessages } from '@/modules/product/components/flash-messages/flash-messages-component'
import { HomepageProductsGrid } from './homepage-products-grid'

export function HomepageProducts(): JSX.Element {
  return (
    <div className="relative">
      <ProductFlashMessages />
      <HomepageProductsGrid />
    </div>
  )
}
